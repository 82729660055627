import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, toggleDialog, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {toggleDialog ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={toggleDialog}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomDialog({
  open,
  toggleDialog,
  title,
  children,
  buttonLabel,
  buttonAction,
  setIsLoading,
}) {
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    toggleDialog();
    setTimeout(() => {
      buttonAction();
      setIsLoading(false);
    }, 1200);
  };

  return (
    <Dialog
      open={open}
      onClose={toggleDialog}
      aria-labelledby="customized-dialog-title"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="customized-dialog-title" onClose={toggleDialog}>
          {title}
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button autoFocus variant="contained" color="primary" type="submit">
            {buttonLabel}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
