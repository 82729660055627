import React from 'react';
import {
  Dashboard,
  AccountTree,
  FileCopy,
  /* Search,
  BarChart,
  AssignmentTurnedIn,
  People, */
  Settings,
  MenuBook,
} from '@material-ui/icons';

export const MenuItems = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: <Dashboard className='menu-icon' />,
  },
  {
    path: '/project-hub',
    title: 'Project Hub',
    icon: <AccountTree className='menu-icon' />,
  },
  {
    path: '/file-manager',
    title: 'File Manager',
    icon: <FileCopy className='menu-icon' />,
  },
  /*
  PAGES FOR FUTURE SCOPE
  {
    path: "/smart-search",
    title: "Smart Search",
    icon: <Search className="menu-icon" />,
  },
  {
    path: "/data-visualizer",
    title: "Data Visualizer",
    icon: <BarChart className="menu-icon" />,
  },
  {
    path: "/task-manager",
    title: "Task Manager",
    icon: <AssignmentTurnedIn className="menu-icon" />,
  },
  {
    path: "/activity-monitor",
    title: "Activity Monitor",
    icon: <People className="menu-icon" />,
  }, */
  {
    path: '/settings',
    title: 'Settings',
    icon: <Settings className='menu-icon' />,
  },
  {
    path: '/wiki',
    title: 'Wiki',
    icon: <MenuBook className='menu-icon' />,
  },
];
