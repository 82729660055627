import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import config from './config';
import store from './redux/store';
import './index.css';
import App from './App';
import apiconfig from './aws-exports';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  Storage: {
    region: config.storage.REGION,
    bucket: config.storage.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: apiconfig,
});

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root')
);
