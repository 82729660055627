import React from "react";

// Components
import { TextField } from "@material-ui/core";
import Select from "react-select";

// Utilities
import { makeStyles } from "@material-ui/core/styles";
import { statuses } from "../utils/constants";

const ProjectForm = ({ formState, handleChange, handleStatusChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.gridContainer}>
      <TextField
        fullWidth
        name="client"
        className={classes.textInput}
        label="Client"
        variant="outlined"
        required
        defaultValue={formState.client || ""}
        onBlur={(e) => handleChange(e)}
      />
      <TextField
        fullWidth
        name="centerName"
        className={classes.textInput}
        label="Center Name"
        variant="outlined"
        required
        defaultValue={formState.centerName || ""}
        onBlur={(e) => handleChange(e)}
      />
      <TextField
        fullWidth
        name="city"
        className={classes.textInput}
        label="City"
        variant="outlined"
        defaultValue={formState.city || ""}
        onBlur={(e) => handleChange(e)}
      />
      <TextField
        fullWidth
        name="state"
        className={classes.textInput}
        label="State"
        variant="outlined"
        required
        defaultValue={formState.state || ""}
        onBlur={(e) => handleChange(e)}
      />
      <TextField
        fullWidth
        name="projectCenterType"
        className={classes.textInput}
        label="Project/Center Type"
        variant="outlined"
        required
        defaultValue={formState.projectCenterType || ""}
        onBlur={(e) => handleChange(e)}
      />
      <TextField
        fullWidth
        name="locationType"
        className={classes.textInput}
        label="Location Type"
        variant="outlined"
        required
        defaultValue={formState.locationType || ""}
        onBlur={(e) => handleChange(e)}
      />
      <TextField
        fullWidth
        name="secondaryLocationLabel"
        className={classes.textInput}
        label="Secondary Location Label"
        variant="outlined"
        defaultValue={formState.secondaryLocationLabel || ""}
        onBlur={(e) => handleChange(e)}
      />
      <TextField
        fullWidth
        name="tertiaryLocationLabel"
        className={classes.textInput}
        label="Tertiary Location Label"
        variant="outlined"
        defaultValue={formState.tertiaryLocationLabel || ""}
        onBlur={(e) => handleChange(e)}
      />
      <div style={{ position: "relative", zIndex: 20, marginTop: 16 }}>
        <Select
          name="status"
          options={statuses}
          placeholder="Select Project Status"
          maxMenuHeight="250px"
          defaultValue={{ label: formState.status, value: formState.status }}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onChange={(e) => {
            handleStatusChange(e.value);
          }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) !important",
    background: "white",
    paddingLeft: 70,
    paddingRight: 70,
    paddingTop: 30,
    paddingBottom: 30,
    width: "60%",
    display: "inline-block",
  },
  paddingContainer: {
    padding: theme.spacing(2),
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat( 2, minmax(250px, 1fr) )",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width:615px)"]: {
      gridTemplateColumns: "repeat( 1, minmax(250px, 1fr) )",
    },
    columnGap: "16px",
    width: "100%",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  textInput: {
    backgroundColor: "white",
    marginTop: "16px",
  },
  button: {
    width: 120,
    marginTop: "16px",
    marginLeft: "16px",
  },
}));

export default ProjectForm;
