import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export default function AuthenticatedRoute({
  component: C,
  appProps,
  ...rest
}) {
  // Determines the routes
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isConfirmedUser = useSelector((state) => state.auth.isConfirmedUser);

  // If authenticated and confirmed user, load authenticated routes
  if (isAuthenticated && isConfirmedUser) {
    return (
      <Route {...rest} render={(props) => <C {...props} {...appProps} />} />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={`?redirect=${props.location.pathname}${props.location.search}`}
          />
        )}
      />
    );
  }
}
