import React, { useState, useEffect } from "react";

// Components
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import {
  NoteAdd,
  CloudDownload,
  GetApp,
  DeleteForever,
} from "@material-ui/icons";
import { DropzoneArea } from "material-ui-dropzone";
import CustomDialog from "../components/CustomDialog";

// Utils
import formatter from "../utils/formatter";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

// Data
import {
  uploadFile,
  downloadFile,
  listFiles,
  deleteFile,
} from "../utils/file-actions";

export default function FileTable({ projectId }) {
  const classes = useStyles();

  // Files Table State
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);

  // Upload Dialog State
  const [dialog, setDialog] = useState(false);
  const toggleDialog = () => setDialog(!dialog);
  const [uploadFiles, setUploadFiles] = useState([]);

  // Get files on page load and projectId change
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      handleListFiles();
      setIsLoading(false);
    }, 1200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  async function handleListFiles() {
    if (projectId !== "") {
      const listedFiles = await listFiles(projectId);
      setFiles(listedFiles);
    }
  }

  function handleDownloadFile(fileName) {
    downloadFile({ projectId, fileName });
  }

  async function handleBulkDownloadFiles() {
    await Promise.all(
      files.map(async (file) => {
        let fileName = file.key;
        return downloadFile({ projectId, fileName });
      })
    );
  }

  async function handleBulkUploadFiles() {
    if (uploadFiles === undefined || uploadFiles.length === 0) {
      toast.info("No file(s) selected to upload.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setUploadIsLoading(true);
    await Promise.all(
      uploadFiles.map(async (file) => {
        return uploadFile({ projectId, file });
      })
    )
      .then(async () => await handleListFiles())
      .catch((err) =>
        toast.error("An error occured when trying to bulk download files.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      )
      .finally(() => {
        setUploadIsLoading(false);
      });
    // Finish loading
  }

  async function handleDeleteFile(fileName) {
    await deleteFile({ projectId, fileName });
  }

  return (
    <>
      <CustomDialog
        open={dialog}
        toggleDialog={toggleDialog}
        title={"Upload File(s)"}
        buttonLabel={"Upload File(s)"}
        buttonAction={async () => await handleBulkUploadFiles()}
        setIsLoading={setIsLoading}
      >
        <div className={classes.dropzoneContainer}>
          <DropzoneArea
            showFileNames
            dropzoneClass={classes.dropzone}
            filesLimit={20}
            onChange={(files) => setUploadFiles(files)}
          />
        </div>
      </CustomDialog>
      <Paper
        variant="outlined"
        style={{
          backgroundColor: "ghostwhite",
          padding: "20px 30px",
          margin: "36px 0",
        }}
      >
        <MaterialTable
          title="Project Files"
          columns={[
            { title: "Name", field: "key" },
            {
              title: "Last Modified",
              field: "lastModified",
              render: (rowData) =>
                formatter.readableDateTime(rowData.lastModified),
            },
            {
              title: "Size",
              field: "size",
              render: (rowData) => formatter.readableBytes(rowData.size),
            },
          ]}
          data={files}
          isLoading={isLoading || uploadIsLoading}
          actions={[
            {
              icon: () => <NoteAdd />,
              tooltip: "Upload File(s)",
              isFreeAction: true,
              onClick: (event) => toggleDialog(),
            },
            {
              icon: () => <CloudDownload />,
              tooltip: "Download All",
              isFreeAction: true,
              onClick: (event) => {
                var downloadAll = window.confirm(
                  "Are you sure that you want to download all of the files for this project?"
                );
                if (downloadAll) {
                  setIsLoading(true);
                  setTimeout(() => {
                    handleBulkDownloadFiles();
                    setIsLoading(false);
                  }, 1200);
                }
              },
            },
            {
              icon: () => <GetApp />,
              tooltip: "Download File",
              onClick: (event, rowData) => {
                setIsLoading(true);
                setTimeout(() => {
                  handleDownloadFile(rowData.key);
                  setIsLoading(false);
                }, 1200);
              },
            },
            {
              icon: () => <DeleteForever />,
              tooltip: "Delete File",
              onClick: (event, rowData) => {
                var deleteFile = window.confirm(
                  `Are you sure that you want to delete file: ${rowData.key}`
                );
                if (deleteFile) {
                  setIsLoading(true);

                  setTimeout(() => {
                    handleDeleteFile(rowData.key);
                    const dataDelete = [...files];
                    setFiles(
                      dataDelete.filter((file) => file.key !== rowData.key)
                    );
                    setIsLoading(false);
                  }, 1200);
                }
              },
            },
          ]}
        />
      </Paper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  dropzoneContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  dropzone: {
    padding: "20px",
  },
}));
