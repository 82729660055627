import { Storage } from "aws-amplify";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";

// TODO: Manage state from the components using these functions (isLoading, payload, etc.)

/**
Get the URL for a file from the S3 bucket
@param {string} projectId - id of the project of interest
@param {string} fileName - the name of the file to download
**/
export const getFileUrl = ({ projectId, fileName }) => {
  let fileUrl = null;

  const customPrefix = {
    public: `${projectId}/${fileName}`,
  };

  Storage.get("", { customPrefix: customPrefix })
    .then((result) => {
      fileUrl = result;
    })
    .catch((err) => {
      console.error(err);
    });
  return fileUrl;
};

/**
Upload a file to S3 given a projectId
@param {string} projectId - id of the project of interest
@param {file} file - the file selected to be uploaded
/** TODO: Replace spaces and characters () in file names to avoid download bug
**/
export const uploadFile = async ({ projectId, file }) => {
  if (!file) {
    toast.info("No file(s) selected to upload.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return null;
  }

  const filename = file.name;

  // Sets the prefix for the upload file path
  const customPrefix = {
    public: `${projectId}/`,
  };

  try {
    await Storage.put(filename, file, {
      customPrefix: customPrefix,
      contentType: file.type,
    });
    toast.success(`File '${filename}' uploaded successfully!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (err) {
    toast.error("An error occurred while uploading", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

/**
Download a file from the S3 bucket
@param {string} projectId - id of the project of interest
@param {string} fileName - the name of the file to download
**/
export const downloadFile = ({ projectId, fileName }) => {
  const customPrefix = {
    public: `${projectId}/${fileName}`,
  };

  Storage.get("", { customPrefix: customPrefix })
    .then((result) => {
      fetch(result)
        .then((res) => res.blob())
        .then((blob) => saveAs(blob, fileName))
        .then((res) => {
          toast.success(`File '${fileName}' downloaded successfully!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          toast.error("An error occurred while downloading.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    })
    .catch((err) => {
      toast.error("An error occurred while fectching file to download.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  return;
};

/**
Lists files in a specific project
@param {string} projectId - id of the project of interest
**/
export async function listFiles(projectId) {
  const customPrefix = {
    public: `${projectId}/`,
  };

  try {
    let files = (await Storage.list("", { customPrefix: customPrefix })).filter(
      (file) => file.key !== ""
    );
    return files;
  } catch (err) {
    console.error(err);
  }
}

/**
Delete a specific file
@param {string} projectId - id of the project of interest
@param {string} fileId - the name of the file to delete
**/
export const deleteFile = ({ projectId, fileName }) => {
  const customPrefix = {
    public: `${projectId}/${fileName}`,
  };

  Storage.remove("", { customPrefix: customPrefix })
    .then((result) =>
      toast.success(`File '${fileName}' deleted successfully!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    )
    .catch((err) =>
      toast.error("An error occured while deleting file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    );
};
