import React, { useState, useEffect } from "react";

// Components
import { Typography, Button } from "@material-ui/core";
import { Edit, ArrowBack } from "@material-ui/icons";
// import ProjectModal from "../components/ProjectModal";
import ProjectCard from "../components/ProjectCard";
import ContactTable from "../components/ContactTable";
import FileTable from "../components/FileTable";
import StatusBanner from "../components/StatusBanner";
import { Link } from "react-router-dom";
import CustomDialog from "../components/CustomDialog";
import ProjectForm from "../components/ProjectForm";

// Utilities
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

// Data
import { API } from "aws-amplify";
import { getProject as GetProject } from "../graphql/queries";
import { updateProject as UpdateProject } from "../graphql/mutations";

const ProjectDetailView = ({ match }) => {
  const classes = useStyles();

  const projectId = match.params.id;

  // Update Project State
  const initialProjectState = {
    client: "",
    centerName: "",
    city: null,
    state: "",
    projectCenterType: "",
    locationType: "",
    secondaryLocationLabel: null,
    tertiaryLocationLabel: null,
    createdAt: "",
    updatedAt: "",
    status: "",
  };
  // Read-only until update successful
  const [project, setProject] = useState(initialProjectState);
  const [formState, setFormState] = useState(initialProjectState);

  function handleChange(e) {
    const field = e.target.name;
    const value = e.target.value;
    setFormState({ ...formState, [field]: value });
  }

  function handleStatusChange(newStatus) {
    setFormState({ ...formState, status: newStatus });
  }
  const [, /* isLoading */ setIsLoading] = useState(false);

  const [updateProject, setUpdateProject] = useState(false);

  const toggleUpdateProject = () => {
    setUpdateProject(!updateProject);
  };

  async function handleUpdateProject() {
    const removeFromProject = ["createdAt", "updatedAt"];
    const filteredProject = Object.fromEntries(
      Object.entries(formState).filter(
        ([key, val]) => !removeFromProject.includes(key)
      )
    );
    try {
      const updatedProject = await API.graphql({
        query: UpdateProject,
        variables: { input: filteredProject },
      });
      toast.success("Project Updated Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // Update read-only project state
      setProject(updatedProject.data.updateProject);
    } catch (err) {
      console.error(err);
    }
  }

  const getProject = async () => {
    try {
      const retrievedProject = await API.graphql({
        query: GetProject,
        variables: { id: projectId },
      });
      setProject(retrievedProject.data.getProject);
      setFormState(retrievedProject.data.getProject);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [createdAtFormatted, setCreatedAtFormatted] = useState("");

  useEffect(() => {
    if (project.createdAt !== "" && project.createdAt) {
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "America/Chicago",
      };

      setCreatedAtFormatted(
        new Intl.DateTimeFormat("en-US", options).format(
          new Date(project.createdAt)
        )
      );
    }
  }, [project.createdAt]);

  return (
    <div className={classes.container}>
      <div className={classes.flexContainer}>
        <Link to="/project-hub" className="no-underline">
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<ArrowBack />}
          >
            Back to All Projects
          </Button>
        </Link>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleUpdateProject}
          startIcon={<Edit />}
        >
          Edit Project
        </Button>
      </div>
      <Typography
        className={`${classes.text}  ${classes.heading}`}
        variant="h3"
      >
        {project.client} — {project.centerName}
      </Typography>
      {/* Project Edit Modal, invisible until EDIT is clicked */}
      <CustomDialog
        open={updateProject}
        toggleDialog={toggleUpdateProject}
        title={"Update Project"}
        buttonLabel={"Update"}
        buttonAction={handleUpdateProject}
        setIsLoading={setIsLoading}
      >
        <div className={classes.dialogInnerContainer}>
          <ProjectForm
            formState={formState}
            handleChange={handleChange}
            handleStatusChange={handleStatusChange}
          />
        </div>
      </CustomDialog>

      <div className={classes.gridContainer}>
        <ProjectCard title="Project Info">
          <Typography variant="subtitle1" className={classes.text}>
            Date Created: <strong>{createdAtFormatted}</strong>
          </Typography>
          <Typography
            variant="h5"
            className={classes.text}
            style={{ marginTop: "1em" }}
          >
            Project Location
          </Typography>
          {project.city ? (
            <p className={classes.text}>
              City: <strong>{project.city}</strong>
            </p>
          ) : (
            <p className={classes.text}>
              <strong>{project.centerName}</strong>
            </p>
          )}
          <p className={classes.text}>
            State: <strong>{project.state}</strong>
          </p>
          <Typography variant="subtitle1" className={classes.text}>
            Location Info:
          </Typography>
          <ul className={classes.text}>
            <li>
              Location Type: <strong>{project.locationType}</strong>
            </li>
            {project.secondaryLocationLabel && (
              <li>
                Secondary Location Label:{" "}
                <strong>{project.secondaryLocationLabel}</strong>
              </li>
            )}
            {project.tertiaryLocationLabel && (
              <li>
                Tertiary Location Label:{" "}
                <strong>{project.tertiaryLocationLabel}</strong>
              </li>
            )}
          </ul>
        </ProjectCard>
        <div className={classes.innerGridContainer}>
          <StatusBanner status={project.status} />
          <ProjectCard title="Client">
            <Typography variant="subtitle1" className={classes.text}>
              <strong>{project.client}</strong>
            </Typography>
            <Typography
              variant="h5"
              className={classes.text}
              style={{ margin: "1em 0" }}
            >
              Project/Center Type
            </Typography>
            <Typography variant="subtitle1" className={classes.text}>
              <strong>{project.projectCenterType}</strong>
            </Typography>
          </ProjectCard>
        </div>
      </div>

      {/* Project Contacts */}
      <ContactTable projectId={projectId} />

      {/* Project Files */}
      <FileTable projectId={projectId} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 120,
    paddingLeft: "5vw",
    paddingRight: "5vw",
  },
  flexContainer: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    paddingBottom: "2em",
  },
  heading: {
    marginBottom: 20,
  },
  text: {
    textAlign: "left",
  },
  sectionTitle: {
    textAlign: "left",
    display: "flex",
    flex: 1,
  },
  rowPaddingContainer: {
    padding: theme.spacing(2),
  },
  projectInfo: {
    backgroundColor: "ghostwhite",
    padding: 25,
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 20,
  },
  projectStatus: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    backgroundColor: "mediumseagreen",
    fontWeight: "bold",
    fontSize: 48,
    color: "white",
    width: "100%",
    marginBottom: 15,
  },
  file: {
    maxWidth: 200,
  },
  icon: {
    height: 150,
    width: "auto",
  },
  overlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) !important",
    background: "white",
    paddingLeft: 70,
    paddingRight: 70,
    paddingTop: 15,
    paddingBottom: 15,
    width: "70%",
    display: "inline-block",
  },
  textInput: {
    backgroundColor: "white",
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  largeChip: {
    height: 40,
    fontSize: 13,
    margin: 5,
    width: "auto",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat( 2, minmax(250px, 1fr) )",
    columnGap: "16px",

    "& div": {
      height: "100%",
    },
  },
  innerGridContainer: {
    display: "grid",
    gridTemplateRows: "0.5fr 1fr",
    rowGap: "16px",
  },
}));

export default ProjectDetailView;
