import React, { useState, useEffect, useRef } from "react";

// Components
import { TextField, Card, CardContent, CardActions, CardHeader} from "@material-ui/core";
import LoaderButton from "../components/LoaderButton";

// Utilities
import { createStyles, makeStyles } from "@material-ui/core/styles";
import isEmail from "validator/es/lib/isEmail";
import isEmpty from "validator/es/lib/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/actions/authActions";
import { clearErrors } from "../redux/actions/errorActions";
import { usePrevious } from "../utils/custom-hooks";

const Login = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [msg, setMsg] = useState(null);

  // Read from the redux store
  const isLoading = useSelector((state) => state.auth.isLoading);
  const error = useSelector((state) => state.error);

  // Allows us to use the store's dispatch
  const dispatch = useDispatch();

  /** Handle form validation for the signup form
   * @param email - user's email
   * @param password - user's password
   **/
  const validateLoginForm = (email, password) => {
    // Check for invalid email
    if (!isEmail(email)) {
      return false;
    }

    // Check if password is empty
    if (isEmpty(password)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (validateLoginForm(email.trim(), password.trim())) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [email, password]);

  // Gets the previous error
  const prevError = usePrevious(error);

  // componentDidUpdate
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      // componentDidUpdate logic
      if (error !== prevError) {
        // Check for login error
        if (error.id === "LOGIN_FAIL") {
          setMsg(error.msg);
        } else {
          setMsg(null);
        }
      }
    }
  }, [error, prevError]);

  useEffect(() => {
    // Called on component unmount, clears the errors
    return () => {
      dispatch(clearErrors());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = () => {
    dispatch(login(email, password));
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftGridItem}>
        <div className={classes.introContainer}>
          <h1 className={classes.title}>Welcome to Hoffman Analytics!</h1>
          <p className={classes.text}>
            a commercial real estate feasibility analysis tool aiming to
            streamline the process of analyzing multi-use commercial real estate
            markets.
          </p>
        </div>
        <p className={classes.textOverlay}>Hoffman</p>
      </div>
      <div className={classes.rightGridItem}>
        <img
          className={classes.logo}
          src="../.././hsg_logo.png"
          alt="Hoffman Strategy Group logo"
        />
        <form className={classes.formContainer} noValidate autoComplete="off">
          <Card className={classes.card}>
            <CardHeader className={classes.header} title="Login" />
            <CardContent>
              <div>
                <TextField
                  error={msg !== null}
                  fullWidth
                  id="email"
                  type="email"
                  label="Email"
                  placeholder="Email"
                  margin="normal"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  error={msg !== null}
                  fullWidth
                  id="password"
                  type="password"
                  label="Password"
                  placeholder="Password"
                  margin="normal"
                  helperText={msg}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </CardContent>
            <CardActions>
              <LoaderButton
                variant="contained"
                size="large"
                bgColor="#904A2D"
                fontColor="white"
                icon="login"
                isLoading={isLoading}
                className={classes.loginBtn}
                onClick={() => handleLogin()}
                disabled={isButtonDisabled}
              >
                Login
              </LoaderButton>
            </CardActions>
          </Card>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "grid",
      overflow: "hidden",
    },
    leftGridItem: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      gridColumn: "1",
      height: "100vh",
      width: "50vw",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      justifyContent: "center",
      backgroundColor: "#363740",
      overflow: "hidden",
    },
    introContainer: {
      width: "60%",
      textAlign: "left",
      margin: "0 auto 20px auto",
      zIndex: "2",
    },
    title: {
      color: "#fff",
      fontWeight: "bolder",
    },
    text: {
      color: "#fff",
      fontWeight: "light",
    },
    textOverlay: {
      position: "absolute",
      transformOrigin: "left top",
      transform: "rotate(-90deg)",
      top: "820px",
      fontSize: "13em",
      fontWeight: "bolder",
      padding: 0,
      margin: 0,
      color: "#904A2D",
      opacity: "0.2",
      overflow: "hidden",
      zIndex: "1",
    },
    rightGridItem: {
      display: "flex",
      flexDirection: "column",
      gridColumn: "2",
      height: "100vh",
      width: "50vw",
      minWidth: "400px",
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      width: "60%",
      minWidth: "300px",
      margin: "0 auto",
    },
    formContainer: {
      width: "60%",
      minWidth: "300px",
      margin: `0 auto`,
    },
    loginBtn: {
      marginTop: "10px",
      flexGrow: "1",
      color: "white",
    },
    header: {
      textAlign: "center",
      background: "#363740",
      color: "#fff",
    },
    card: {
      marginTop: "10px",
    },
  })
);

export default Login;
