/**
 * Converts a date object to a readable date and time
 *
 * @param {Int} dateObject The date object.
 */
function readableDateTime(dateObject) {
  return new Intl.DateTimeFormat("en-US", {
    timeStyle: "short",
    dateStyle: "short",
  }).format(dateObject);
}

/**
 * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
 *
 * @param {Int} bytes The number of bytes.
 */
function readableBytes(bytes) {
  var i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
}

export default { readableDateTime, readableBytes };
