/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      client
      centerName
      city
      state
      projectCenterType
      locationType
      secondaryLocationLabel
      tertiaryLocationLabel
      createdAt
      updatedAt
      status
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        client
        centerName
        city
        state
        projectCenterType
        locationType
        secondaryLocationLabel
        tertiaryLocationLabel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      projectId
      name
      company
      role
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectId
        name
        company
        role
        email
        phoneNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
