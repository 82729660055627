import isEmpty from "validator/es/lib/isEmpty";

/**
 * Converts a date object to a readable date and time
 *
 * @param {Int} dateObject The date object.
 */
function validateEquals(val1, val2) {
  // Don't validate unless the passwords are the same
  if ((!isEmpty(val1) || !isEmpty(val2)) && val1 === val2) {
    return true;
  }
  return false;
}

export default { validateEquals };
