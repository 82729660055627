import React from "react";

// Utilities
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: 64,
      height: 300,
      width: "100%",
      position: "relative",
      textAlign: "center",
      backgroundColor: "black",
      backgroundSize: "cover",
      backgroundPosition: "75% 25%",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "1",
      "&::before": {
        content: "''", // ::before and ::after both require content
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: "-1",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
    },

    title: {
      color: "white",
      fontWeight: "bold",
      fontSize: "4rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "3rem",
      },
      // eslint-disable-next-line no-useless-computed-key
      ["@media (max-width:750px)"]: {
        fontSize: "2rem",
      },
      margin: 0,
    },
    description: {
      color: "white",
      fontWeight: "400",
      fontSize: 18,
      maxWidth: 800,
      padding: "0 36px",
      marginBottom: 0,
    },
  })
);

export default function PageCover({ title, description = null, img }) {
  const classes = useStyles();
  return (
    <div className={classes.root} style={{ backgroundImage: `url(${img})` }}>
      <h1 className={classes.title}>{title}</h1>
      {description && <p className={classes.description}>{description}</p>}
    </div>
  );
}
