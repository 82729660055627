import React from "react";

// Components
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// Utilities
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "& a": {
      textDecoration: "none",
    },

    "& span": {
      fontWeight: "bold",
    },
  },
  media: {
    height: 140,
  },
});

export default function DashboardCard({
  image,
  title,
  description,
  cta1,
  cta1Link,
  cta2 = null,
  cta2Link = null,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={image} title={title} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Link to={cta1Link}>
          <Button size="small" color="primary">
            {cta1}
          </Button>
        </Link>
        {cta2 && cta2Link && (
          <Link to={cta2Link}>
            <Button size="small" color="primary">
              {cta2}
            </Button>
          </Link>
        )}
      </CardActions>
    </Card>
  );
}
