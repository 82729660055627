import {
  USER_LOADING,
  USER_LOADED,
  USER_IS_CONFIRMED,
  AUTH_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  SET_USER,
  LOGIN_FAIL,
  NEW_PASSWORD_LOADING,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_FAIL,
  LOGOUT_SUCCESS,
  TOGGLE_HIDE_EMAIL,
} from "../actions/types";

const initialState = {
  isAuthenticating: true,
  isAuthenticated: false,
  isConfirmedUser: false,
  user: null,
  isLoading: false,
  email: "",
  hideEmail: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isAuthenticating: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        email: action.payload.email,
      };
    case USER_IS_CONFIRMED:
      return {
        ...state,
        isConfirmedUser: true,
        email: action.payload.email,
        user: null,
      };
    case NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        isConfirmedUser: true,
        user: null,
      };
    case NEW_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case LOGIN_LOADING:
    case NEW_PASSWORD_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        isLoading: false,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        email: action.payload.challengeParam.userAttributes.email,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        isConfirmedUser: false,
        user: null,
        isLoading: false,
        email: "",
      };
    case TOGGLE_HIDE_EMAIL:
      return {
        ...state,
        hideEmail: action.payload,
      };
    default:
      return state;
  }
}
