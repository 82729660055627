import React from "react";

// Components
import { Paper, Typography } from "@material-ui/core";

// Utilities
import { makeStyles } from "@material-ui/core/styles";

const ProjectCard = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Paper variant="outlined" className={classes.card}>
        <Typography variant="h5" className={classes.title}>
          {props.title}
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {props.subtitle}
        </Typography>
        {props.children}
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "ghostwhite",
    padding: 25,
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 20,
  },
  title: {
    textAlign: "left",
  },
  subtitle: {
    textAlign: "left",
    paddingBottom: 20,
  },
}));

export default ProjectCard;
