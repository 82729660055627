import React, { useState, useEffect } from "react";

// Components
import { Paper } from "@material-ui/core";

// Utilities
import { makeStyles } from "@material-ui/core/styles";

const StatusBanner = ({ status }) => {
  const classes = useStyles();
  const [color, setColor] = useState("");

  useEffect(() => {
    let col = "";
    if (status === "Completed") {
      col = "mediumseagreen";
    } else if (status === "In Progress") {
      col = "orange";
    } else {
      col = "red";
    }
    setColor(col);
  }, [status]);

  return (
    <>
      <Paper
        className={classes.banner}
        variant="outlined"
        style={{
          backgroundColor: `${color}`,
          textTransform: "uppercase",
        }}
      >
        {status}
      </Paper>
    </>
  );
};

const useStyles = makeStyles(() => ({
  banner: {
    textAlign: "center",
    padding: 20,
    fontWeight: "bold",
    fontSize: 48,
    color: "white",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default StatusBanner;
