import React, { useState, useEffect } from "react";

// Components
import PageCover from "../components/PageCover";
import { useMediaQuery, Typography } from "@material-ui/core";
import Select from "react-select";
import FileTable from "../components/FileTable";

// Data
import { API } from "aws-amplify";
import { listProjects as ListProjects } from "../graphql/queries";

const FileManager = () => {
  const isSmallScreen = useMediaQuery("(max-width: 1100px)");

  const [projects, setProjects] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchProjects() {
    try {
      const projectsData = await API.graphql({
        query: ListProjects,
      });
      setProjects(projectsData.data.listProjects.items);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    fetchProjects();
  }, []);

  useEffect(() => {
    setOptions(
      projects.map((project) => {
        const op = {
          label: `Client: ${project?.client} - Center Name: ${project?.centerName} - Location: ${project?.city} ${project?.state} - Center Type: ${project?.projectCenterType}`,
          value: `${project.id}`,
        };
        return op;
      })
    );
    setIsLoading(false);
  }, [projects]);

  return (
    <>
      <PageCover
        title="File Manager"
        description="Quickly locate files for a desired client and location. Easily upload and download files to a project with bulk capabilities."
        img="../.././file-manager.jpg"
      />
      <div className={isSmallScreen ? "container" : "responsive-container"}>
        <Typography variant="h6" align="left" style={{ marginBottom: "0.5em" }}>
          Select a Project:
        </Typography>
        <div style={{ position: "relative", zIndex: 20 }}>
          <Select
            id="select-file"
            options={options}
            placeholder="Choose a project"
            maxMenuHeight="250px"
            isLoading={isLoading}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            onChange={(e) => setProjectId(e.value)}
          />
        </div>
        <FileTable style={{ zIndex: 1 }} projectId={projectId} />
      </div>
    </>
  );
};

export default FileManager;
