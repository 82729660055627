import React from 'react';

// Components
import DashboardCard from '../components/DashboardCard';
import PageCover from '../components/PageCover';

// Utilities
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Dashboard = () => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width: 1100px)');

  return (
    <>
      <PageCover
        title='Welcome to Hoffman Analytics!'
        description='A commercial real estate feasibility analysis platform enabled to
          streamline the process of analyzing multi-use commercial real estate
          markets.'
        img='../.././dashboard.jpg'
      />
      <div className={!isSmallScreen ? 'container' : 'responsive-container'}>
        <div className={classes.dashboardCardsWidth}>
          <div className={classes.dashboardCards}>
            <DashboardCard
              image='../.././project-hub.jpg'
              title='Project Hub'
              description='The Swiss-army knife for any project. View recent projects, browse project information, update project details,
and create new projects.'
              cta1='Continue to Project Hub'
              cta1Link='/project-hub'
            />
            <DashboardCard
              image='../.././file-manager.jpg'
              title='File Manager'
              description='Quickly locate files for a desired client and location. Easily upload and download files to a project with bulk capabilities.'
              cta1='Continue to File Manager'
              cta1Link='/file-manager'
            />
            <DashboardCard
              image='../.././wiki.jpg'
              title='Wiki'
              description='All the documentation necessary to ensure the best experience possible for all users and developers.'
              cta1='Continue to the Wiki'
              cta1Link='/wiki'
            />
          </div>
          <div className={classes.textContainer}>
            <h2>About Hoffman Strategy Group</h2>
            <p>
              Hoffman Strategy Group is a boutique, national real estate
              advisory firm. Over our collective firm experience of 85 years, we
              have cultivated the unique and distinct ability to dive deeply
              into retail, residential and commercial market and feasibility
              analysis, master planning and development in order to identify
              those factors that lead to successful real estate projects.
            </p>
            <p>
              We utilize the most current and relevant technology, including
              mobile device data and GIS-mapping, to supplement our
              understanding of the customers that will support any given
              project.
            </p>
            <p>
              This skill set and experience enables us to be an integral part of
              a client’s acquisition, investment, development, leasing and
              marketing teams. From identifying markets appropriate for
              multi-family expansion based on a client’s criteria to completing
              national and regional “white space” studies and sales forecasting
              to support a retailer or restaurant’s expansion strategy, our work
              allows clients to establish priorities and develop real estate
              projects that are based on sound data analytics.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  dashboardCards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gridGap: '1em',
    marginBottom: '3em',
  },
  dashboardCardsWidth: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  textContainer: {
    textAlign: 'left',
    marginBottom: '3em',

    '& h2': {
      fontSize: 24,
    },

    '& p': {
      fontSize: 18,
    },
  },
}));

export default Dashboard;
