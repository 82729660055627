// Auth
/* Used when checking current session */
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
/* Used when logging in */
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
/* Used when setting permanent password */
export const NEW_PASSWORD_LOADING = "NEW_PASSWORD_LOADING";
export const NEW_PASSWORD_SUCCESS = "NEW_PASSWORD_SUCCESS";
export const NEW_PASSWORD_FAIL = "NEW_PASSWORD_FAIL";
/* Used when logging out */
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
/* Used to keep track of new users who need to set permanant password */
export const USER_IS_CONFIRMED = "USER_IS_CONFIRMED";
export const SET_USER = "SET_USER";
export const TOGGLE_HIDE_EMAIL = "TOGGLE_HIDE_EMAIL";

// Error Types
/* Used to simplify error displays */
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
