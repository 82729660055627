import React, { useState, useEffect } from "react";

// Components
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";

// Utils
import { toast } from "react-toastify";

// Data
import API from "@aws-amplify/api";
import { listContacts as ListContacts } from "../graphql/queries";
import {
  createContact as CreateContact,
  updateContact as UpdateContact,
  deleteContact as DeleteContact,
} from "../graphql/mutations";

export default function ContactTable({ projectId }) {
  // Contacts Table State
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchContacts = async () => {
    try {
      const contactsData = await API.graphql({
        query: ListContacts,
        variables: { filter: { projectId: { eq: projectId } } },
      });

      setContacts(contactsData.data.listContacts.items);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      fetchContacts();
      setIsLoading(false);
    }, 1200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateContact = async (contact) => {
    try {
      const createdContact = await API.graphql({
        query: CreateContact,
        variables: { input: contact },
      });
      toast.success("Contact created successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setContacts([...contacts, createdContact.data.createContact]);
    } catch (err) {
      toast.error("An error occured while creating contact.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleUpdateContact = async (contact) => {
    try {
      await API.graphql({
        query: UpdateContact,
        variables: { input: contact },
      });
      toast.success("Contact updated successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error("An error occurred while updating contact.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleDeleteContact = async (id) => {
    try {
      await API.graphql({
        query: DeleteContact,
        variables: { input: { id } },
      });
      toast.success("Contact deleted successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while deleting contact.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Paper
      variant="outlined"
      style={{
        backgroundColor: "ghostwhite",
        padding: "20px 30px",
        marginTop: 36,
      }}
    >
      <MaterialTable
        title="Project Contact Information"
        isLoading={isLoading}
        columns={[
          { title: "Name", field: "name" },
          { title: "Company", field: "company" },
          { title: "Role", field: "role" },
          {
            title: "Email",
            field: "email",
            render: (rowData) => (
              <a href={`mailto: ${rowData.email}`}>{rowData.email}</a>
            ),
          },
          {
            title: "Phone Number",
            field: "phoneNumber",
            render: (rowData) => (
              <a href={`tel: ${rowData.phoneNumber}`}>{rowData.phoneNumber}</a>
            ),
          },
        ]}
        data={contacts}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setIsLoading(true);
              setTimeout(() => {
                const { name, company, role, email, phoneNumber } = newData;

                const newContact = {
                  projectId,
                  name,
                  company,
                  role,
                  email,
                  phoneNumber,
                };

                // Update contact in the DB
                const createdContact = handleCreateContact(newContact);

                setContacts([...contacts, createdContact]);

                resolve();
                setIsLoading(false);
              }, 1200);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setIsLoading(true);
              setTimeout(() => {
                const tableIndex = oldData.tableData.id;
                const { id, name, company, role, email, phoneNumber } = newData;
                const updatedContact = {
                  id,
                  name,
                  company,
                  role,
                  email,
                  phoneNumber,
                };

                // Update contact in the DB
                handleUpdateContact(updatedContact);

                // Fix table info
                const dataUpdate = [...contacts];
                dataUpdate[tableIndex] = newData;
                setContacts(dataUpdate);

                resolve();
                setIsLoading(false);
              }, 1200);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setIsLoading(true);
              setTimeout(() => {
                const tableIndex = oldData.tableData.id;
                const contactIndex = oldData.id;

                // Delete contact from the DB
                handleDeleteContact(contactIndex);

                const dataDelete = [...contacts];
                dataDelete.splice(tableIndex, 1);
                setContacts(dataDelete);

                resolve();
                setIsLoading(false);
              }, 1200);
            }),
        }}
      />
    </Paper>
  );
}
