/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      client
      centerName
      city
      state
      projectCenterType
      locationType
      secondaryLocationLabel
      tertiaryLocationLabel
      createdAt
      updatedAt
      status
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      client
      centerName
      city
      state
      projectCenterType
      locationType
      secondaryLocationLabel
      tertiaryLocationLabel
      createdAt
      updatedAt
      status
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      client
      centerName
      city
      state
      projectCenterType
      locationType
      secondaryLocationLabel
      tertiaryLocationLabel
      createdAt
      updatedAt
      status
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      projectId
      name
      company
      role
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      projectId
      name
      company
      role
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      projectId
      name
      company
      role
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
