import React from 'react';

// Components
import PageCover from '../../components/PageCover';

// Utilities
// import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import 'react-notion/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css'; // only needed for code highlighting
import { NotionRenderer } from 'react-notion';

import response from './wiki.json';

const Wiki = () => {
  // const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width: 1100px)');

  return (
    <>
      <PageCover
        title='Wiki'
        description='All the documentation necessary to ensure the best experience possible for all users and developers.'
        img='../.././wiki.jpg'
      />
      <div
        className={!isSmallScreen ? 'container' : 'responsive-container'}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div style={{ maxWidth: 768, textAlign: 'left' }}>
          <NotionRenderer blockMap={response} />
        </div>
      </div>
    </>
  );
};

// const useStyles = makeStyles(() => ({}));

export default Wiki;
