import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './utils/CustomRoutes/AuthenticatedRoute';
import UnauthenticatedRoute from './utils/CustomRoutes/UnauthenticatedRoute';
import ConfirmUserRoute from './utils/CustomRoutes/ConfirmUserRoute';

// Main screens
import Login from './containers/Login';
import UpdatePassword from './containers/UpdatePassword';
import Dashboard from './containers/Dashboard';
import ProjectHub from './containers/ProjectHub';
import FileManager from './containers/FileManager';
/* import SmartSearch from './containers/SmartSearch';
import DataVisualizer from "./containers/DataVisualizer";
import TaskManager from "./containers/TaskManager";
import ActivityMonitor from "./containers/ActivityMonitor"; */
import Settings from './containers/Settings';
import Wiki from './containers/Wiki/Wiki.jsx';
import NotFound from './containers/NotFound';

// Subscreens of other screens
import ProjectDetailView from './containers/ProjectDetailView';

// import RowAllocation from "./containers/Retail/RowAllocation/RowAllocation";

export default function Routes({ appProps }) {
  return (
    <Switch>
      {/* Anyone can access these routes */}
      <UnauthenticatedRoute
        path='/'
        exact
        component={Login}
        appProps={appProps}
      />

      {/* Must be authenticated to access these routes */}
      <ConfirmUserRoute
        path='/update-password'
        exact
        component={UpdatePassword}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path='/dashboard'
        exact
        component={Dashboard}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path='/project-hub'
        exact
        component={ProjectHub}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path='/file-manager'
        exact
        component={FileManager}
        appProps={appProps}
      />
      {/*
      <AuthenticatedRoute
        path="/smart-search"
        exact
        component={SmartSearch}
        appProps={appProps}
      />
      PAGE FOR FUTURE SCOPE
      <AuthenticatedRoute
        path="/data-visualizer"
        exact
        component={DataVisualizer}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/task-manager"
        exact
        component={TaskManager}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/activity-monitor"
        exact
        component={ActivityMonitor}
        appProps={appProps}
      /> */}
      <AuthenticatedRoute
        path='/settings'
        exact
        component={Settings}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path='/wiki'
        exact
        component={Wiki}
        appProps={appProps}
      />

      {/* Subsections for other routes */}
      <AuthenticatedRoute
        path='/project-hub/:id'
        exact
        component={ProjectDetailView}
        appProps={appProps}
      />

      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
