import { Auth } from "aws-amplify";
import { returnErrors } from "./errorActions";
import { toast } from "react-toastify";
import {
  USER_LOADED,
  USER_LOADING,
  USER_IS_CONFIRMED,
  AUTH_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  SET_USER,
  LOGIN_FAIL,
  NEW_PASSWORD_LOADING,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_FAIL,
  LOGOUT_SUCCESS,
  TOGGLE_HIDE_EMAIL,
} from "./types";

// Check Token & Load User
export const loadUser = () => async (dispatch) => {
  // User loading (isAuthenticating)
  dispatch({ type: USER_LOADING });

  Auth.currentSession()
    .then((res) => {
      const data = {};
      data.email = res.idToken.payload.email;
      return data;
    })
    .then((data) => {
      dispatch({ type: USER_LOADED, payload: data });
      dispatch({ type: USER_IS_CONFIRMED, payload: data });
    })
    .catch((err) => {
      dispatch({ type: AUTH_ERROR });
      dispatch(returnErrors(err, "", "AUTH_ERROR"));
    });

  // TODO: Update if necessary
  /* // Load user attributes
  try {
    let user = await Auth.currentAuthenticatedUser();
    const { attributes } = user;
    // dispatch({ type: TOGGLE_HIDE_EMAIL, payload:  });
  } catch (err) {
    console.error(err);
  } */
};

// Login User
export const login = (email, password) => (dispatch) => {
  // Login loading (isLoading)
  dispatch({ type: LOGIN_LOADING });

  Auth.signIn(email, password)
    .then((user) => {
      Auth.currentSession()
        .then((res) => {
          const data = {};
          data.email = res.idToken.payload.email;
          dispatch({ type: USER_IS_CONFIRMED, payload: data });
        })
        .catch((err) => {
          dispatch({
            type: SET_USER,
            payload: user,
          });
        })
        .finally(() => {
          dispatch({ type: LOGIN_SUCCESS });
        });
    })
    .catch((err) => {
      dispatch({ type: LOGIN_FAIL });
      err.name === "NotAuthorizedException"
        ? dispatch(
            returnErrors("Incorrect email or password.", 400, "LOGIN_FAIL")
          )
        : dispatch(
            returnErrors("Incorrect email or password.", 400, "LOGIN_FAIL")
          );
    });
};

// Set Permanent Password
export const setPermanentPassword = (user, newPassword) => (dispatch) => {
  // New password loading (isLoading)
  dispatch({ type: NEW_PASSWORD_LOADING });

  Auth.completeNewPassword(user, newPassword)
    .then((res) => {
      dispatch({
        type: NEW_PASSWORD_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch({ type: NEW_PASSWORD_FAIL });
      dispatch(returnErrors(err.message, 400, "NEW_PASSWORD_FAIL"));
    });
};

// Logout User
export const logout = () => (dispatch) => {
  Auth.signOut().then(dispatch({ type: LOGOUT_SUCCESS }));
};

// Update hide email preference
export const toggleHideEmail = (hideEmail) => async (dispatch) => {
  try {
    let user = await Auth.currentAuthenticatedUser();

    let value = hideEmail ? 1 : 0;

    await Auth.updateUserAttributes(user, {
      "custom:hideEmail": `${value}`,
    });

    dispatch({ type: TOGGLE_HIDE_EMAIL, payload: hideEmail });
    toast.success("Email preference updated!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (err) {
    toast.error("An error occurred while trying to update email preference.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
