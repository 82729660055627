import React from "react";

// Components
import { Button, LinearProgress } from "@material-ui/core";
import { LockOpen, CloudUpload, CloudDownload } from "@material-ui/icons";

// Utilities
import { makeStyles } from "@material-ui/core";

const LoaderButton = ({
  isLoading,
  className = "",
  disabled = false,
  icon,
  bgColor,
  fontColor,
  ...props
}) => {
  const classes = useStyles();
  const renderIcon =
    icon === "login" ? (
      <LockOpen />
    ) : icon === "upload" ? (
      <CloudUpload />
    ) : (
      <CloudDownload />
    );

  const color = fontColor === "white" ? (disabled ? "lightgrey" : "white") : "";

  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.button}
        disabled={disabled || isLoading}
        size="large"
        variant="contained"
        startIcon={renderIcon}
        {...props}
        // Embedded styles, change based on current page
        style={{
          backgroundColor: `${bgColor}`,
          color: `${color}`,
        }}
      >
        {props.children}
      </Button>
      {isLoading && <LinearProgress />}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
    width: "100%",
    marginBottom: 20,
  },
  button: {
    width: "100%",
  },
}));

export default LoaderButton;
