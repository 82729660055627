import React, { useState, useEffect } from "react";

// Components
import { Add } from "@material-ui/icons";
import MaterialTable from "material-table";
import ProjectForm from "../components/ProjectForm";
import { Link } from "react-router-dom";
import PageCover from "../components/PageCover";
import CustomDialog from "../components/CustomDialog";

// Utilities
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

// Data
import { API } from "aws-amplify";
import { listProjects as ListProjects } from "../graphql/queries";
import {
  updateProject as UpdateProject,
  deleteProject as DeleteProject,
  createProject as CreateProject,
} from "../graphql/mutations";

const ProjectHub = () => {
  // Styles
  const classes = useStyles();
  const isSmallScreen = useMediaQuery("(max-width: 1100px)");

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const initialState = {
    client: "",
    centerName: "",
    city: null,
    state: "",
    projectCenterType: "",
    locationType: "",
    secondaryLocationLabel: null,
    tertiaryLocationLabel: null,
    status: "Not Started",
  };

  const [formState, setFormState] = useState(initialState);

  function handleChange(e) {
    const field = e.target.name;
    const value = e.target.value;
    setFormState({ ...formState, [field]: value });
  }

  function handleStatusChange(newStatus) {
    setFormState({ ...formState, status: newStatus });
  }

  // Project Data Logic
  const [projects, setProjects] = useState([]);

  async function fetchProjects() {
    try {
      const projectsData = await API.graphql({
        query: ListProjects,
      });
      setProjects(projectsData.data.listProjects.items);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      fetchProjects();
      setIsLoading(false);
    }, 1200);
  }, []);

  async function handleCreateProject() {
    try {
      const createdProject = await API.graphql({
        query: CreateProject,
        variables: { input: formState },
      });
      setProjects([...projects, createdProject.data.createProject]);
      setFormState(initialState);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleUpdateProject(project) {
    try {
      await API.graphql({
        query: UpdateProject,
        variables: { input: project },
      });
    } catch (err) {
      console.error(err);
    }
  }

  async function handleDeleteProject(id) {
    try {
      await API.graphql({
        query: DeleteProject,
        variables: { input: { id } },
      });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <PageCover
        title="Project Hub"
        description="The Swiss-army knife for any project. View recent projects, browse project information, update project details,
and create new projects."
        img="../.././project-hub.jpg"
      />
      <div
        className={!isSmallScreen ? "container" : "responsive-container"}
        style={{ marginBottom: "3em" }}
      >
        <MaterialTable
          title="All Projects"
          columns={[
            {
              title: "Client",
              field: "client",
              render: (rowData) => (
                <Link to={`/project-hub/${rowData.id}`}>{rowData.client}</Link>
              ),
            },
            { title: "Center Name", field: "centerName" },
            { title: "City", field: "city" },
            { title: "State", field: "state" },
            { title: "Project/Center Type", field: "projectCenterType" },
            { title: "Location Type", field: "locationType" },
            {
              title: "Secondary Location Label",
              field: "secondaryLocationLabel",
            },
            {
              title: "Tertiary Location Label",
              field: "tertiaryLocationLabel",
            },
          ]}
          // Add the projects from the query in the useEffect
          data={projects}
          isLoading={isLoading}
          actions={[
            {
              icon: () => (
                <div className="table-button">
                  <Add />
                  Create New Project
                </div>
              ),
              tooltip: "Create Project",
              isFreeAction: true,
              onClick: (event) => toggleModal(),
            },
          ]}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const tableIndex = oldData.tableData.id;
                  const {
                    id,
                    client,
                    centerName,
                    city,
                    state,
                    projectCenterType,
                    locationType,
                    secondaryLocationLabel,
                    tertiaryLocationLabel,
                  } = newData;
                  const updatedProject = {
                    id,
                    client,
                    centerName,
                    city,
                    state,
                    projectCenterType,
                    locationType,
                    secondaryLocationLabel,
                    tertiaryLocationLabel,
                  };

                  // Update project in the DB
                  handleUpdateProject(updatedProject);

                  // Fix table info
                  const dataUpdate = [...projects];
                  dataUpdate[tableIndex] = newData;
                  setProjects(dataUpdate);

                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const tableIndex = oldData.tableData.id;
                  const projectIndex = oldData.id;

                  // Delete project from the DB
                  handleDeleteProject(projectIndex);

                  const dataDelete = [...projects];
                  dataDelete.splice(tableIndex, 1);
                  setProjects(dataDelete);

                  resolve();
                }, 1000);
              }),
          }}
        />
        <CustomDialog
          open={openModal}
          toggleDialog={toggleModal}
          title={"Create New Project"}
          buttonLabel={"Create"}
          buttonAction={handleCreateProject}
          setIsLoading={setIsLoading}
        >
          <div className={classes.dialogInnerContainer}>
            <ProjectForm
              formState={formState}
              handleChange={handleChange}
              handleStatusChange={handleStatusChange}
            />
          </div>
        </CustomDialog>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogInnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

export default ProjectHub;
