import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AppNavigation from "./components/AppNavigation";
import Routes from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { loadUser } from "./redux/actions/authActions";
import "./App.css";

function App() {
  // Ensures that the onLoad function runs before the application is displayed
  const isAuthenticating = useSelector((state) => state.auth.isAuthenticating);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isConfirmedUser = useSelector((state) => state.auth.isConfirmedUser);

  // Allows us to use the store's dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !isAuthenticating && (
      <>
        {isAuthenticated && isConfirmedUser ? (
          <AppNavigation>
            <Routes />
            <ToastContainer
              position="bottom-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </AppNavigation>
        ) : (
          <Routes />
        )}
      </>
    )
  );
}

export default App;
