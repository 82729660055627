import React, { useState, useEffect } from 'react';

// Components
import {
  Grid,
  Drawer,
  SwipeableDrawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  AppBar,
  Toolbar,
  Hidden,
  CssBaseline,
  /* Badge, */
  useMediaQuery,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  /* Search, */
  Menu as MenuIcon,
  AccountCircle,
  /* Notifications, */
} from '@material-ui/icons';

// Utilities
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { logout } from '../redux/actions/authActions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { MenuItems } from '../utils/menu-items';

const AppNavigation = ({ window, children }) => {
  // Register theme info
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width: 750px)');

  // Navbar State
  const [selected, setSelected] = useState('Dashboard');
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const location = useLocation();
  // Check current path to make sure file remembers page on page reload
  useEffect(() => {
    const currentPathname = location.pathname.split('/')[1];
    const currentItem = MenuItems.find(
      (item) => item.path === `/${currentPathname}`
    );
    if (currentItem) {
      setSelected(currentItem.title);
    }
  }, [location]);

  // User/Usability State
  let history = useHistory();
  let dispatch = useDispatch();
  const email = useSelector((state) => state.auth.email);
  const hideEmail = useSelector((state) => state.auth.hideEmail);
  function handleLogout() {
    // Signs the user out by clearing the local storage
    dispatch(logout());
    history.push('');
  }

  // Profile Menu State
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const profileMenuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Link
        to='/settings'
        style={{ textDecoration: 'none', color: '#000000DE' }}
      >
        <MenuItem>Settings</MenuItem>
      </Link>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          handleLogout();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <div className={`${classes.root} App`}>
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open sidebar'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Grid container alignItems='center' justify='space-between'>
            <Typography variant='h5'>{selected}</Typography>
            <Grid item>
              {/* <IconButton color="inherit">
                <Search />
              </IconButton>
              <IconButton color="inherit">
                {// TODO: Make badge correspond to number of notifcations}
                <Badge badgeContent={4} color="secondary">
                  <Notifications />
                </Badge>
              </IconButton> */}
              {!isSmallScreen && !hideEmail && (
                <Typography variant='subtitle1' className={classes.account}>
                  {email}
                </Typography>
              )}
              <IconButton
                edge='end'
                aria-label='Account Settings'
                aria-controls={profileMenuId}
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
                color='inherit'
              >
                <AccountCircle />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMenu}

      <nav className={classes.drawer} aria-label='navigation menu'>
        <Hidden smUp implementation='css'>
          <SwipeableDrawer
            className={classes.sidebar}
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onOpen={handleDrawerToggle}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.sidebarLinks,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <AppSidebar
              classes={classes}
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
              selected={selected}
              setSelected={setSelected}
            />
          </SwipeableDrawer>
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Drawer
            className={classes.sidebar}
            classes={{
              paper: classes.sidebarLinks,
            }}
            variant='permanent'
            open
          >
            <AppSidebar
              classes={classes}
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
              selected={selected}
              setSelected={setSelected}
            />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {/* Main Content of the site */}
        {children}
      </main>
    </div>
  );
};

const AppSidebar = ({
  classes,
  mobileOpen,
  handleDrawerToggle,
  selected,
  setSelected,
}) => {
  return (
    <div>
      <div>
        <div className={classes.flexContainer}>
          <img
            className={classes.logo}
            src='../../.././h_logo.png'
            alt='HSG Logo'
          />
          <p className={classes.title}>Hoffman Analytics</p>
        </div>
        <List component='nav' className={classes.gray}>
          <Divider className={classes.divider} />
          {MenuItems.map(({ path, title, icon }, index) => (
            <div key={index}>
              <ListItem
                button
                component={Link}
                to={path}
                className={
                  title === selected ? classes.active : classes.listItem
                }
                onClick={() => {
                  setSelected(title);
                  if (mobileOpen) handleDrawerToggle();
                }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={title} />
              </ListItem>
              {index === 2 && <Divider className={classes.divider} />}
            </div>
          ))}
        </List>
      </div>
    </div>
  );
};

const sidebarWidth = 225;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  sidebar: {
    width: sidebarWidth,
    flexShrink: 0,
  },
  sidebarLinks: {
    background: '#363740',
    [theme.breakpoints.up('sm')]: {
      width: sidebarWidth,
      flexShrink: 0,
    },
  },
  divider: {
    height: 5,
  },
  profile: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  whiteText: {
    color: 'white',
  },
  navIcon: {
    color: 'white',
    height: 34,
    width: 'auto',
  },
  linkIcon: {
    color: 'white',
    height: 34,
    width: 'auto',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 64,
    padding: '0 16px',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: sidebarWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${sidebarWidth}px)`,
      marginLeft: sidebarWidth,
    },
    color: 'white',
    backgroundColor: '#363740',
    border: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  link: {
    color: 'white',
    textDecoration: 'none',
  },
  logoContainer: {
    height: 64,
  },
  logo: {
    height: 30,
    width: 'auto',
  },
  title: {
    color: '#9fa2b4',
    fontWeight: 'bolder',
    fontSize: 16,
    margin: '0 auto',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gray: {
    color: '#9fa2b4',
    padding: 0,
  },
  listItem: {
    backgroundColor: '#363740',
    borderLeft: '4px solid #363740',
  },
  active: {
    backgroundColor: '#3e4049 !important',
    color: 'white',
    borderLeft: '4px solid white',
  },
  account: {
    display: 'inline',
    margin: '0px 5px 0px 20px',
  },
}));

export default AppNavigation;
