export default {
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_oHoTak9It",
    APP_CLIENT_ID: "3t2l8ttj09tdnppqra2c1b3v2b",
    IDENTITY_POOL_ID: "us-east-2:821426d6-6cc7-4d1b-8aa5-94d93ff02184"
  },
  storage: {
    REGION: "us-east-2",
    BUCKET: "hoffman-strategy",
    IDENTITY_POOL_ID: "us-east-2:821426d6-6cc7-4d1b-8aa5-94d93ff02184"
  }
};
