import React, { useState, useRef, useEffect } from 'react';

// Components
import {
  TextField,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
  Button,
  /* FormControlLabel,
  Switch, */
} from '@material-ui/core';
import LoaderButton from '../components/LoaderButton';
import { useMediaQuery } from '@material-ui/core';
import PageCover from '../components/PageCover';

// Utilities
// import { makeStyles } from "@material-ui/core/styles";
import validator from '../utils/validator';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../redux/actions/authActions';
import { returnErrors } from '../redux/actions/errorActions';
import { usePrevious } from '../utils/custom-hooks';
import { clearErrors } from '../redux/actions/errorActions';
import { toast } from 'react-toastify';

const Settings = () => {
  // const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width: 1100px)');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState(null);

  // User/Usability State
  let history = useHistory();
  let dispatch = useDispatch();
  const error = useSelector((state) => state.error);

  function handleLogout() {
    // Signs the user out by clearing the local storage
    dispatch(logout());
    history.push('');
  }

  // Gets the previous error
  const prevError = usePrevious(error);

  // componentDidUpdate
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      // componentDidUpdate logic
      if (error !== prevError) {
        // Check for login error
        if (error.id === 'UPDATE_PASSWORD_FAIL') {
          setMsg(error.msg);
        } else {
          setMsg(null);
        }
      }
    }
  }, [error, prevError]);

  useEffect(() => {
    // Called on component unmount, clears the errors
    return () => {
      dispatch(clearErrors());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSubmit() {
    setIsLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, currentPassword, newPassword);
      })
      .then((data) => {
        setCurrentPassword('');
        setNewPassword('');
        setVerifyPassword('');
        dispatch(clearErrors());
        toast.success('Password updated successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.message === 'Incorrect username or password.') {
          dispatch(
            returnErrors(
              "'Current Password' is incorrect.",
              400,
              'UPDATE_PASSWORD_FAIL'
            )
          );
        } else if (
          err.message ===
          "1 validation error detected: Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6"
        ) {
          dispatch(
            returnErrors(
              "'New Password' must have length greater than or equal to 8 and include lowercase letter(s), uppercase letter(s), special character(s) and number(s).",
              400,
              'UPDATE_PASSWORD_FAIL'
            )
          );
        } else dispatch(returnErrors(err.message, 400, 'UPDATE_PASSWORD_FAIL'));
      });
    setIsLoading(false);
  }

  return (
    <>
      <PageCover
        title='Settings'
        description='Edit your preferences, change your password, or submit an issue.'
        img='../.././settings.jpg'
      />
      <div className={!isSmallScreen ? 'container' : 'responsive-container'}>
        <div style={{ textAlign: 'left', marginBottom: '3em' }}>
          <Typography variant='h5'>Edit Your Preferences</Typography>
          <p>There are currently no preferences to update</p>
        </div>
        <form noValidate autoComplete='off'>
          <Card style={{ marginBottom: '3em', maxWidth: 800 }}>
            <CardHeader title='Change Your Password' align='left' />
            <CardContent>
              <div>
                <TextField
                  error={msg !== null}
                  fullWidth
                  id='current-password'
                  type='password'
                  label='Current Password'
                  placeholder='********'
                  margin='normal'
                  autoComplete='on'
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <TextField
                  error={msg !== null}
                  fullWidth
                  id='new-password'
                  type='password'
                  label='New Password'
                  placeholder='********'
                  margin='normal'
                  autoComplete='on'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                  error={msg !== null}
                  fullWidth
                  id='verify-new-password'
                  type='password'
                  label='Retype New Password'
                  placeholder='**********'
                  margin='normal'
                  autoComplete='on'
                  value={verifyPassword}
                  helperText={msg}
                  onChange={(e) => setVerifyPassword(e.target.value)}
                />
              </div>
            </CardContent>
            <CardActions>
              <LoaderButton
                variant='contained'
                size='large'
                bgColor='#904A2D'
                fontColor='white'
                icon='login'
                isLoading={isLoading}
                onClick={() => handleSubmit()}
                disabled={
                  !validator.validateEquals(newPassword, verifyPassword) ||
                  currentPassword === ''
                }
              >
                Change Password
              </LoaderButton>
            </CardActions>
          </Card>
        </form>
        <Typography variant='h5' align='left'>
          Submit an Issue
        </Typography>
        <p align='left' style={{ marginBottom: '3em' }}>
          Did you experience an issue or inconvenience while using the platform?{' '}
          <a href='mailto:matt@hoffmanstrategy.com?subject=Support Ticket Request&body=Description: '>
            Submit a support ticket to Matt
          </a>{' '}
          and he would be happy to help!
        </p>
        <Typography variant='h5' align='left'>
          Logout Current User
        </Typography>
        <p align='left' style={{ marginBottom: '3em' }}>
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={handleLogout}
          >
            Logout
          </Button>
        </p>
      </div>
    </>
  );
};

// const useStyles = makeStyles(() => ({}));

export default Settings;
