import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default function AuthenticatedRoute({
  component: C,
  appProps,
  ...rest
}) {
  // Determines the routes
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isConfirmedUser = useSelector((state) => state.auth.isConfirmedUser);

  const redirect = querystring("redirect");

  // If authenticated but user not confirmed
  if (isAuthenticated && !isConfirmedUser) {
    return (
      <Route {...rest} render={(props) => <C {...props} {...appProps} />} />
    );
  }
  // If authenticated and a confirmed user
  else if (isAuthenticated && isConfirmedUser) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={redirect === "" || redirect === null ? "/dashboard" : redirect}
          />
        )}
      />
    );
  }
  // Otherwise, not authenticated
  else {
    return <Route {...rest} render={() => <Redirect to={``} />} />;
  }
}
